import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { environment } from '@env/environment';
import { API_BASE_URL } from './index';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: API_BASE_URL,
      useValue: `${environment.api.brasilprev.url}/portabilidade-bff`
    }
  ]
})
export class NewDiversificationModule {}
